import { ActionTree } from 'vuex';
import { AuthState } from './types';
import { RootState } from '../../types';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import DownloadUtils from '@/shared/utils/DownloadUtils';
import { Credentials } from '@/shared/model/smallPayloadModels/credentials';
import { defaultBackendAccount } from '@/shared/backend/account';
// import { msalInstance } from 'vue-msal-browser';
import msalPlugin, { msalInstance } from 'vue-msal-browser';

const logger = new Logger('actions.auth');
export const actions: ActionTree<AuthState, RootState> = {
  updateAzureData({ commit }, payload: any) {
    commit('setAzureData', payload);
  },
  updateInitialRedirect({ commit }, payload: any) {
    commit('setInitialRedirect', payload);
  },

  async login({ commit, dispatch }, payload: Credentials) {
    // authenticate to get the access token
    return defaultBackendAccount
      .authenticate(payload.username!, payload.password!, payload.rememberMe)
      .then((response: any) => {
        commit('setToken', response.data);
        commit('setAccountId', response.data.userId);
        return response;
      })
      .catch((e: any) => {
        logger.error(e);
        throw e;
      });
  },
  async getZrNummer({ commit,rootGetters }) {
    // (GSP-143) tried to read `auth/isUserOnlyGs` but no value yet
    // commit('setZrNummer', "112203");
    // return "540000,646004,440810"; //537000,885101,112203,217200,745200
    return await defaultBackendAccount
      .getZrNummer()
      .then((result) => {
        // result.data = null;
        if (!result?.data?.errors && result?.data) {
          // for `Dev` env use roles info
          if (result.data.isDevEnv) {
            let roles = JSON.parse(result.data.result)?.rolesData;
            roles = JSON.parse(roles); // should parse again to convert string to array
            let rolesZrNums = roles?.map((role: any) => {
                if (role) {
                  return role.zrNummer?.trim();
                }
              })
              .filter((x: any) => !!x); // filter `null` values from response
            // console.log('rolesZrNums :>> ', rolesZrNums);
            let zrNums = rolesZrNums?.join(',') || '-'; // (GSP-108) (AD-84) if no roles set for `GsUser` in AdminPanel for ZrNummer then show/load nothing
            // console.log('zrNums :>> ', zrNums);
            // commit('setZrNummer', result.data.zrNummer);
            // commit('setOriginalZrNummer', zrNums);
            commit('setZrNummer', zrNums);
          }
          else {
            // logic for `Prod`/`Staging` environment
            let zrResult = result.data.result;
            // check if string is JSON
            if(/^\s*(\{|\[)/.test(result.data.result)){
              try{
                  zrResult =JSON.parse(result.data.result)?.zrNummer
              }catch(e){
                  console.error(e);
                  zrResult = null;
              }
           }
           if (zrResult.includes('"') || zrResult.includes('\\')) {
            zrResult = zrResult.replaceAll('\\','').replaceAll('"','');
           }
          //  console.log("zrNum res", zrResult);
           let roles = JSON.parse(result.data.result)?.rolesData;
           let rolesParsed = JSON.parse(roles);
          //  console.log("zrNum roles", roles);
           let zrNumsWithRoles = rolesParsed?.filter((x:any) => x.moduleRoles.some(((y:any) => y.roles.length> 0)));
          //  console.log("zrNum xxx", zrNumsWithRoles);
           let filterModules = zrNumsWithRoles?.map((x:any) => {  return {zrNummer: x.zrNummer, modules: x.moduleRoles.filter((y:any) => y.roles.length > 0)}})
          //  console.log('zrNum filterModules', filterModules);
          //  let xxx = roles.filter((x:any) => x.moduleRoles.any(((y:any) => y.roles.length> 0)));
           
           let modulesZrNummers: any = {};
    
           filterModules?.forEach((x:any) => {
            // console.log(x);
            // console.log(x.modules.some((z:any)=> z.name.toLowerCase().includes('gutschriften')));
            if (x.modules.some((z:any)=> z.name.toLowerCase().includes('gutschriften'))) {
              modulesZrNummers.zrdocumentZrNummer = modulesZrNummers.zrdocumentZrNummer ? `${modulesZrNummers.zrdocumentZrNummer},${x.zrNummer}` : x.zrNummer;
            }
            if (x.modules.some((z:any)=> z.name.toLowerCase().includes('bersic'))) {
              modulesZrNummers.invoiceZrNummer = modulesZrNummers.invoiceZrNummer ? `${modulesZrNummers.invoiceZrNummer},${x.zrNummer}` : x.zrNummer;
            }
            if (x.modules.some((z:any)=> z.name.toLowerCase().includes('nderungs'))) {
              modulesZrNummers.voucherZrNummer = modulesZrNummers.voucherZrNummer ? `${modulesZrNummers.voucherZrNummer},${x.zrNummer}` : x.zrNummer;
            }


           });
          //  console.log('zrNum mu', modulesZrNummers);

            commit('setZrNummer', zrResult);
            commit('setZrNummerModulesRoles', modulesZrNummers);
          }
        } else {
          commit('setZrNummer', null);
        }

        return result;
      })
      .catch((e) => {
        commit('setZrNummer', null);
        module_utils.error('', commit, e);
      });
  },
  async getSupplierTooltipInfo({ commit }) {

    return defaultBackendAccount
      .getSupplierTooltipInfo()
      .then((result) => {
        console.log('action getSupplierTooltipInfo :>> ', result);
        if (result?.data) {
      
          commit('setSupplierTooltipInfo', JSON.parse(result.data.result));
          // localStorage.setItem('zrNummerGsUserTest', result.data);
        }
        return result.data;
      })
      .catch((e) => {
        module_utils.error('', commit, e);
      });
  },
  async getTestInvoiceGsUserZr({ commit }) {
    // (GSP-107) users can change `ZrNummers` so CANNOT cache like this
    // if (localStorage.getItem('zrNummerGsUserTest')) {
    //   const zrNummerLs = localStorage.getItem('zrNummerGsUserTest');
    //   commit('setTestGsUserZrNummer', zrNummerLs);
    //   return zrNummerLs;
    // }

    return defaultBackendAccount
      .getTestInvoiceGsUserZr()
      .then((result) => {
        console.log('action getTestInvoiceGsUserZr :>> ', result);
        if (result?.data) {
          if (Array.isArray(result.data)) {
            result.data = result.data.join(',');
          }

          commit('setTestGsUserZrNummer', result.data);
          // localStorage.setItem('zrNummerGsUserTest', result.data);
        }
        return result.data;
      })
      .catch((e) => {
        module_utils.error('', commit, e);
      });
  },
  async loadAccountDetails({ commit }, id?: number) {
    return defaultBackendAccount
      .getMyAccount()
      .then((account) => {
        commit('setAccount', account.data.account);
      })
      .catch((e) => {
        commit('setAccount', undefined);
        module_utils.error('', commit, e);
      });
  },

  async canSeeAll({ commit }, email: string) {
    return defaultBackendAccount
      .canSeeAllZrDocuments(email)
      .then((response) => {
        const isInAdminGroup = response.data?.value?.length > 0;
        logger.log('isInAdminGroup :>> ', response.data);
        // commit('setIsUserInAzureAdminGroup', isInAdminGroup);
        commit('setUserAdminGroups', response.data?.value);
        return response.data;
      })
      .catch((e: any) => {
        logger.error(e);
        throw e;
      });
  },

  /**
   * Clears the userType effectively logging out the user.
   */
  logout({ commit }) {
    return defaultBackendAccount.logout().then((result) => {
      commit('setAccount', undefined);
      commit('clearTokens');
      return result;
    });
  },
  async getEnvironment({ commit }) {
    return defaultBackendAccount
      .getEnvironment()
      .then((result:any) => {
        commit('setEnvironment', result.data);
        return result.data;
      })
      .catch((e:any) => {
        logger.error(e);
        throw e;
      });
  },
};
